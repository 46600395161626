import { Stack } from "@mui/material";
import { TextField } from "mui-rff";
import { useTranslation } from "react-i18next";
import { required } from "../../../../../utils/validation";
import { FileInput } from "../../../../ui/components/FileInput";
import { CardId } from "../../../../../assets/icons";
import { useOnBoardingFormContext } from "../../../hooks/useOnBoardingFormContext";
import { countrySelectData } from "../../../data/countriesData";
import { TDocTypesComponents } from "../../../models";
import { SelectWithId } from "../../../../ui/components/SelectWIthId";

export const Residence = (props: TDocTypesComponents) => {
  const { domId } = props;
  const { t } = useTranslation("onBoarding", { keyPrefix: "Residence.fields" });
  const {
    files: { Residence },
    uploadedFiles,
  } = useOnBoardingFormContext();

  const ResidenceFile__FirstPage = Residence?.ResidenceFile__FirstPage || {};
  const ResidenceFile__SecondPage = Residence?.ResidenceFile__SecondPage || {};

  return (
    <Stack id={domId} spacing={4}>
      <SelectWithId
        MenuProps={{ sx: { height: window.innerWidth < 400 ? 600 : "auto" } }}
        name='data.country'
        label={t("country")}
        required
        formControlProps={{ id: "country" }}
        data={countrySelectData}
      />
      <TextField name='data.city' label={t("city")} required />
      <TextField name='data.address' label={t("address")} required />
      <TextField name='data.postcode' label={t("postcode")} required />
      <FileInput
        isDeleteBan={uploadedFiles["RESIDENCE_FIRST_PAGE"]}
        document={ResidenceFile__FirstPage}
        namePrefix='files.RESIDENCE_FIRST_PAGE'
        label={t("residence")}
        subtitle={t("residence_subtitle")}
        icon={CardId}
        height={121}
      />
      <FileInput
        isDeleteBan={uploadedFiles["RESIDENCE_SECOND_PAGE"]}
        document={ResidenceFile__SecondPage}
        namePrefix='files.RESIDENCE_SECOND_PAGE'
        label={t("residenceAdditionally")}
        subtitle={t("residenceAdditionally_subtitle")}
        icon={CardId}
        height={121}
      />
    </Stack>
  );
};

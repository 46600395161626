import { TokenData } from "../../auth/types";
import { request } from "./request";

export const requestSms = async (phone: string): Promise<{ salt: string }> => {
  const response = await request({
    path: "/api/v1/user-service/request-code",
    method: "POST",
    body: {
      phone,
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const requestEmailCode = async (
  email: string
): Promise<{ salt: string }> => {
  const response = await request({
    path: "/api/v1/user-service/auth/email-code",
    method: "POST",
    body: {
      email,
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const requestEmail = async (
  email: string
): Promise<{ salt: string }> => {
  const response = await request({
    path: "/api/v1/user-service/addemail",
    method: "POST",
    body: {
      email,
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const authWithCode = async (
  phone: string,
  code: string,
  salt: string
): Promise<TokenData> => {
  const response = await request({
    path: "/api/v1/user-service/authuser-with-code",
    method: "POST",
    body: {
      phone,
      code,
      salt,
    },
  });

  if (response.ok) {
    return response.data.token;
  }

  throw new Error(response.errorMessage);
};

export const confirmEmail = async (
  email: string,
  code: string,
  salt: string
): Promise<TokenData> => {
  const response = await request({
    path: "/api/v1/user-service/confirmemail",
    method: "POST",
    body: {
      email,
      code,
      salt,
    },
  });

  if (response.ok) {
    return response.data.token;
  }

  throw new Error(response.errorMessage);
};

export const authWithEmailCode = async (
  email: string,
  code: string,
  salt: string
): Promise<TokenData> => {
  const response = await request({
    path: "/api/v1/user-service/auth/confirm-email",
    method: "POST",
    body: {
      email,
      code,
      salt,
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

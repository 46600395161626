import { Box, styled } from "@mui/material";

export const CustomRadiosContainer = styled(Box)`
  & > .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
    justify-content: stretch;
    & > .MuiFormControlLabel-root {
      flex: 1 1 0px;
      & > .MuiRadio-root {
        display: none;

        &.Mui-checked ~ .MuiFormControlLabel-label {
          box-shadow: 0px 0px 0px 2px #1976d2;
        }
        & ~ .MuiFormControlLabel-label {
          display: inline-block;
          background-color: white;
          border-radius: 16px;
          font-size: 16px;
          line-height: 24px;
          padding: 16px;
          height: 100%;
          display: inline-flex;
          align-items: center;
          transition: all 0.3s;
        }
      }
    }
  }
`;

export const IframeContainer = styled(Box)`
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  background-color: white;
  & > iframe {
    border: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0px;
  }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   height: 25px;
  //   background-color: white;
  // }
`;

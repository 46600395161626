import { Button, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { WidgetLayout } from "../../../../components/WidgetLayout";
import { useOnBoardingFormContext } from "../../hooks/useOnBoardingFormContext";
import { ReviewStepItem } from "../../components/ReviewStepItem";
import useLocalStorage from "../../../ui/hooks/useLocalStorage";
import { syncVerificationRequest } from "../../network";
import { useVerificationRequestSubmitWithPhone } from "../../hooks/useVerificationRequestSubmitWithPhone";
import { FROM_SHARING } from "../../models";
import { useCallback, useEffect, useMemo } from "react";
import { ErrorAlert } from "../../../ui/components/StyledAlert";

export const OnBoardingShareFormScreen = (props: any) => {
  const {
    formData,
    formConfig,
    verificationRequestId,
    lastAcceptedVerificationId,
    acceptedVerificationCompany,
    updateDocumentIds,
    files,
    setIsSharing,
    setIsSharingNeedFilling,
    verificationCompany,
    documentIds,
    isSharingAccepted,
    setIsSharingAccepted,
  } = useOnBoardingFormContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [, setFromSharing] = useLocalStorage(FROM_SHARING, false);
  const [result, loading, error, submit] =
    useVerificationRequestSubmitWithPhone();
  const [email] = useLocalStorage("email", null);

  useEffect(() => {
    if (location.pathname.includes("sharing") && isSharingAccepted) {
      setIsSharing(true);
      setIsSharingNeedFilling(false);
    } else {
      navigate("../");
    }
  }, [
    location.pathname,
    setIsSharing,
    setIsSharingNeedFilling,
    isSharingAccepted,
    navigate,
  ]);

  const stopSharing = useCallback(
    (success = false) => {
      setIsSharing(false);
      setFromSharing(true);
      if (success) {
        navigate("../success");
      } else {
        navigate("/onboarding-form");
      }
    },
    [navigate, setIsSharing, setFromSharing]
  );

  const onShare = async () => {
    try {
      const res = await syncVerificationRequest(
        verificationRequestId,
        lastAcceptedVerificationId
      );
      if (res) {
        const verification = await updateDocumentIds(documentIds);
        submit(verificationRequestId, verification!);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (result) {
      stopSharing(true);
    }
  }, [result, stopSharing]);

  const isSubmitAvailable = useMemo(() => {
    if (formConfig?.elements) {
      let isAvail = true;
      let index = 0;
      for (let element of formConfig.elements) {
        if (!isAvail) {
          break;
        }
        if (element.type === "AmlStep") {
          // isAvail ===
          //   acceptedVerificationDocuments?.some(doc => {
          //     ['passport', 'id', 'driver_licence'].includes(
          //       Object.keys(doc)[0].toLowerCase(),
          //     );
          //   }) &&
          //   acceptedVerificationDocuments?.some(doc => {
          //     ['selfie'].includes(Object.keys(doc)[0].toLowerCase());
          //   });
          index++;
          continue;
        } else {
          isAvail = formData[index]?.documentType.includes(element.type);
          index++;
        }
      }
      return isAvail;
    }
  }, [formConfig, formData]);

  return (
    <WidgetLayout
      title={isSharingAccepted ? "Review" : "Easy onboarding"}
      subtitle={
        isSharingAccepted
          ? "You will share the following data"
          : `You have already accepted verification at ${acceptedVerificationCompany?.name}. Do you agree to share your data with company ${verificationCompany?.name}?`
      }
    >
      <Stack flexDirection='column' spacing={2}>
        {formConfig?.elements?.map((element, index) => {
          const stepIndex = index.toString();
          return (formData[stepIndex] && !isSharingAccepted) ||
            isSharingAccepted ? (
            <ReviewStepItem
              isSharing
              isSharingAccepted={isSharingAccepted}
              isNeedToFiil={!formData[stepIndex]}
              email={email}
              files={files}
              key={element.type}
              stepConfig={element}
              stepIndex={stepIndex}
              filledDocType={formData[stepIndex]?.documentType}
              formData={formData}
            />
          ) : null;
        })}
      </Stack>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        width='100%'
        mt={4}
        spacing={2}
      >
        {!isSharingAccepted ? (
          <>
            <Button
              variant='contained'
              sx={{
                textTransform: "initial",
                width: "160px",
                height: "44px",
              }}
              color='secondary'
              onClick={() => stopSharing()}
            >
              Go Again
            </Button>
            <Button
              variant='contained'
              onClick={() => setIsSharingAccepted(true)}
              sx={{ width: "160px", height: "44px" }}
            >
              Share
            </Button>
          </>
        ) : (
          <Button
            disabled={loading || !isSubmitAvailable}
            variant='contained'
            onClick={onShare}
            sx={{ width: "160px", height: "44px" }}
          >
            Send
          </Button>
        )}
      </Stack>
      {!loading && error && (
        <ErrorAlert>{error.message || error.toString()}</ErrorAlert>
      )}
    </WidgetLayout>
  );
};

import { SelectData } from "mui-rff";
import { getSortAscending } from "../utils/sortingFunctions";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
//@ts-ignore
import labels from "react-phone-number-input/locale/en";

export type TCountryPhoneCode = { callingCode: string; countryName: string };

export const CountriesPhoneNumbers: SelectData[] = getSortAscending(
  getCountries().map((country) => {
    const callingCode = `+${getCountryCallingCode(country)}`;
    const countryName = labels[country];
    return {
      label: `${countryName} ${callingCode}`,
      value: {
        callingCode,
        countryName,
      },
    };
  }),
  "label"
);

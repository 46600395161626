import { Select, SelectProps } from "mui-rff";

export const SelectWithId = (props: SelectProps) => {
  return (
    <Select
      {...props}
      formControlProps={{
        id: props.name.includes(".") ? props.name.split(".")[1] : props.name,
      }}
    />
  );
};

import {
  MenuItem,
  Stack,
  Typography,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { FC, useState } from "react";
import { ChevronIcon } from "../../../../components/MuiIcons";

type TSelectItem = {
  value?: string;
  label?: string;
  parent?: string;
  name?: string;
  subtitle?: string;
};

export const CustomMultiSelect: FC<{
  data: TSelectItem[];
  name: string;
  label: string;
  onChange: any;
  setValue: any;
  values: string[];
  MenuProps?: any;
  [x: string]: any;
}> = ({
  data,
  name,
  label,
  onChange,
  values,
  setValue,
  // MenuProps,
  ...other
}) => {
  const [openList, setOpenList] = useState<string[]>([]);
  const [open, setOpen] = useState(false);

  const toggle = (name: string) => {
    setOpenList((prev) =>
      prev.includes(name)
        ? prev.filter((elName) => elName !== name)
        : [...prev, name]
    );
  };

  const getRenderValue = (values: string[]) => {
    return values
      .map((el) => {
        const item = data.find((item) => item.value === el);
        return item?.parent ? item.value : item?.label;
      })
      .join(", ");
  };
  return (
    <FormControl id={name} fullWidth {...other}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        label={label}
        onChange={(e) => onChange(e, setValue)}
        value={values}
        renderValue={getRenderValue}
        multiple
        onClick={() => setOpen((prev) => !prev)}
        open={open}
      >
        <Stack alignItems='flex-end'>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
          >
            Done
          </Button>
        </Stack>
        {data.map((elem, index) =>
          elem.name ? (
            <>
              <Stack
                sx={{ padding: "6px 16px", cursor: "pointer" }}
                flexDirection='row'
                justifyContent='start'
                alignItems='center'
                flexWrap='nowrap'
                onClickCapture={(e) => {
                  e.stopPropagation();
                  toggle(elem.name!!);
                }}
                key={index}
              >
                <ChevronIcon
                  sx={{
                    marginLeft: "4px",
                    marginRight: "8px",
                    transition: ".1s",
                    transform: `rotate(${
                      openList.includes(elem.name) ? 270 : 90
                    }deg)  translateX(4px)`,
                  }}
                />
                <Typography sx={{ userSelect: "none" }}>{elem.name}</Typography>
              </Stack>
              {openList.includes(elem.name) && elem.subtitle && (
                <Typography variant='caption' paddingLeft='32px'>
                  {elem.subtitle}
                </Typography>
              )}
            </>
          ) : (
            <MenuItem
              onClick={(e) => e.stopPropagation()}
              key={index}
              value={elem.value}
              sx={{
                display:
                  elem.parent && !openList.includes(elem?.parent!)
                    ? "none"
                    : "block",
                paddingLeft: elem.parent ? "32px" : "",
              }}
            >
              <Checkbox
                size='small'
                checked={values.indexOf(elem.value as string) > -1}
              />
              {elem.label}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

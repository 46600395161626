import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useLoadDocuments } from "../../hooks/useLoadDocuments";
import {
  TOnboardingConfig,
  TVerificationApp,
  TVerificationDocumentDataItem,
  TVerificationDocumentFull,
  TVerificationRequest,
  VerificationAppDocumentType,
} from "../../models";
import { getCompany, setDocumentsToRequest, TCompany } from "../../network";
import { StatusView } from "../../../ui/components/StatusView";
import useLocalStorage from "../../../ui/hooks/useLocalStorage";
import { useLogout } from "../../../../auth/useLogout";

export type TTFormDataStepFile = {
  inputData?: FileList | File | null;
  storedData?: TVerificationDocumentDataItem;
};

export type TFormDataStep = {
  documentType: VerificationAppDocumentType;
  documentId?: string;
  hasChanges?: boolean;
  data?: {
    [key: string]: any;
  };
  files?: {
    [key: string]: TTFormDataStepFile;
  };
};

export type TFormData = {
  [step: string]: TFormDataStep;
};

export type OnBoardingFormContextValue = {
  restoring: boolean;
  isSharingAccepted: boolean;
  formConfig?: TOnboardingConfig;
  formData: TFormData;
  verificationRequestId: string;
  lastAcceptedVerificationId: string;
  documentIds: string[];
  submitStepData: (
    step: string,
    data?: TFormDataStep,
    toReview?: boolean,
    isSharing?: boolean
  ) => void;
  goToPrevious: (step: string) => void;
  isDisabledContinue: boolean;
  disableContinue: (value: boolean) => void;
  updateDocumentIds: (
    ids: string[] | string
  ) => void | Promise<TVerificationRequest | undefined>;
  files: any;
  uploadedFiles: any;
  isSharing: boolean;
  lastAcceptedVerification: TVerificationRequest | null;
  setUploadedFiles: Dispatch<SetStateAction<any>>;
  setIsSharing: Dispatch<SetStateAction<boolean>>;
  setIsSharingAccepted: Dispatch<SetStateAction<boolean>>;
  setIsSharingNeedFilling: Dispatch<SetStateAction<boolean>>;
  setLastAcceptedVerification: Dispatch<
    SetStateAction<TVerificationRequest | null>
  >;
  verificationCompany: TCompany | null;
  acceptedVerificationCompany: TCompany | null;
};

export const OnBoardingFormContext = createContext<OnBoardingFormContextValue>(
  {} as OnBoardingFormContextValue
);

export type TProps = PropsWithChildren<{
  app: TVerificationApp;
  request: TVerificationRequest | any;
}>;

export const OnBoardingFormProvider = (props: TProps) => {
  const { request, app, children } = props;
  const navigate = useNavigate();
  const [lastAcceptedVerification, setLastAcceptedVerification] =
    useState<TVerificationRequest | null>(null);
  const [lastAcceptedVerificationId, setLastAcceptedVerificationId] =
    useState<string>("");
  const [currentRequest, setCurrentRequest] = useState(request);
  const [verificationCompany, setVerificationCompany] =
    useState<TCompany | null>(null);
  const [acceptedVerificationCompany, setAcceptedVerificationCompany] =
    useState<TCompany | null>(null);
  const [isSharing, setIsSharing] = useState(true);
  const [isSharingAccepted, setIsSharingAccepted] = useState(false);
  const [isSharingNeedFilling, setIsSharingNeedFilling] = useState(false);

  const initialDocumentsIds = useMemo(
    () =>
      currentRequest?.documents?.map((x: any) => x.document.documentId) || [],
    [currentRequest]
  );

  useEffect(() => {
    if (request) {
      getCompany(request.companyId).then((company) =>
        setVerificationCompany(company)
      );
    }
  }, [request]);

  useEffect(() => {
    if (lastAcceptedVerification) {
      getCompany(lastAcceptedVerification.companyId).then((company) =>
        setAcceptedVerificationCompany(company)
      );
    }
  }, [lastAcceptedVerification]);

  const files = useMemo(() => {
    const files: any = {};
    currentRequest?.documents.forEach((document: TVerificationDocumentFull) => {
      const documentType = document.document.documentType;
      files[documentType] = {};
      document.data.forEach((data) => {
        if (data.type === "file") {
          const declineReason = document.document.declineReason;
          files[documentType].declineReason = declineReason.length
            ? declineReason
            : null;
          const dataId = data.dataId;
          files[documentType][dataId] = data;
        }
      });
    });
    return files;
  }, [currentRequest]);

  const [documents, documentsLoading, documentsError] = useLoadDocuments(
    currentRequest.id,
    initialDocumentsIds
  );
  const [restoring, setRestoring] = useState(true);
  const [prevFormData, setPrevFormData] = useState<TFormData | null>(null);
  const [formData, setFormData] = useState<TFormData>({});
  const [isDisabledContinue, disableContinue] = useState(false);
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [, setVerificationDocsError] = useLocalStorage("documentError", null);
  const [, , , logout] = useLogout();

  useEffect(() => {
    if (documentsError) {
      setVerificationDocsError(true);
      logout();
    }
  }, [documentsError, setVerificationDocsError, logout]);

  useEffect(() => {
    if (
      lastAcceptedVerification &&
      request?.documents?.length === 0 &&
      isSharing
    ) {
      setCurrentRequest(lastAcceptedVerification);
      setLastAcceptedVerificationId(lastAcceptedVerification.id);
      setPrevFormData(formData);
      navigate("sharing");
    } else if (!isSharingNeedFilling) {
      setCurrentRequest(request);
    }
  }, [
    navigate,
    request,
    isSharing,
    lastAcceptedVerification,
    isSharingNeedFilling,
  ]);

  useEffect(() => {
    setDocumentIds(initialDocumentsIds);
  }, [initialDocumentsIds]);

  const goToNext = useCallback(
    (currentStep: string) => {
      const currentIndex = parseInt(currentStep);
      if (
        currentIndex + 1 <
        (app?.settings?.onboardingConfigParsed?.elements?.length || -1)
      ) {
        navigate(String(currentIndex + 1));
      } else {
        navigate("review");
      }
    },
    [navigate]
  );

  const goToPrevious = useCallback(
    (currentStep: string) => {
      const currentIndex = parseInt(currentStep);
      if (currentIndex - 1 >= 0) {
        navigate(String(currentIndex - 1));
      }
    },
    [navigate]
  );

  const submitStepData = useCallback(
    (
      step: string,
      data?: TFormDataStep,
      toReview?: boolean,
      isSharing?: boolean
    ) => {
      if (data) {
        setFormData((state) => ({
          ...state,
          [step]: data,
        }));
      }
      if (toReview) {
        navigate("review");
      } else if (isSharing) {
        return;
      } else {
        goToNext(step);
      }
    },
    [setFormData, goToNext, navigate]
  );

  const updateDocumentIds = useCallback(
    async (newIds: string[] | string) => {
      if (typeof newIds === "string") {
        setDocumentIds((prev) => [...prev, newIds]);
        setDocumentsToRequest(request.id, [newIds]);
      } else {
        setDocumentIds(newIds);
        return await setDocumentsToRequest(request.id, newIds);
      }
    },
    [request.id]
  );

  const contextValue: OnBoardingFormContextValue = useMemo(() => {
    const result: OnBoardingFormContextValue = {
      submitStepData,
      goToPrevious,
      restoring,
      isSharing,
      formData,
      verificationRequestId: request.id,
      lastAcceptedVerificationId,
      isDisabledContinue,
      disableContinue,
      documentIds,
      updateDocumentIds,
      files,
      uploadedFiles,
      lastAcceptedVerification,
      setUploadedFiles,
      setIsSharing,
      setIsSharingNeedFilling,
      verificationCompany,
      acceptedVerificationCompany,
      setIsSharingAccepted,
      setLastAcceptedVerification,
      isSharingAccepted,
    };

    if (app?.settings?.onboardingConfigParsed) {
      result.formConfig = app.settings.onboardingConfigParsed;
    }

    return result;
  }, [
    restoring,
    app,
    request.id,
    lastAcceptedVerificationId,
    formData,
    submitStepData,
    goToPrevious,
    isDisabledContinue,
    documentIds,
    updateDocumentIds,
    files,
    uploadedFiles,
    setUploadedFiles,
    setIsSharing,
    setIsSharingNeedFilling,
    verificationCompany,
    acceptedVerificationCompany,
    setIsSharingAccepted,
    setLastAcceptedVerification,
    isSharingAccepted,
    lastAcceptedVerification,
    isSharing,
  ]);

  useEffect(() => {
    if (documents && contextValue.formConfig) {
      const map: { [type: string]: TVerificationDocumentFull } = {};
      documents.forEach((document) => {
        map[document.document.documentType] = document;
      });
      const newFormData: TFormData = {};
      contextValue.formConfig.elements?.forEach((element, index) => {
        if (element.allowed_documents) {
          let existDocument: TVerificationDocumentFull | null = null;
          element.allowed_documents.forEach((docType) => {
            if (docType === "AmlStep") {
              existDocument =
                map["Passport"] || map["Id"] || map["DriverLicence"];
            } else if (docType in map) {
              existDocument = map[docType];
            }
          });
          if (existDocument) {
            const doc: TVerificationDocumentFull = existDocument; // TODO: пришлось временно добавить из-за странного бага в TS который опредял тип как never
            const formStepValue: TFormDataStep = {
              documentType: doc.document.documentType,
              documentId: doc.document.documentId,
            };
            doc.data.forEach((item) => {
              if (item.type === "file") {
                formStepValue.files = formStepValue.files || {};
                formStepValue.files[item.dataId] = {
                  storedData: item,
                };
              } else if (item.type === "binary") {
                formStepValue.data = item.dataParsed;
              }
            });
            newFormData[index.toString()] = formStepValue;
          }
        }
      });
      if (prevFormData && !isSharing) {
        setFormData(prevFormData);
      }
      setFormData((state) => ({
        ...state,
        ...newFormData,
      }));
      setRestoring(false);
    }
  }, [
    documents,
    contextValue.formConfig,
    setFormData,
    setRestoring,
    prevFormData,
    isSharing,
  ]);

  return (
    <OnBoardingFormContext.Provider value={contextValue}>
      {children}
    </OnBoardingFormContext.Provider>
  );
};

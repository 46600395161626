import { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { ValidationErrors } from "final-form";
import { TextField } from "mui-rff";
import CloseIcon from "@mui/icons-material/Close";
import { Stack, Box, Button, Modal, IconButton } from "@mui/material";
import { useRequestCode } from "../../auth/useRequestSms";
import { useLogin } from "../../auth/useLogin";
import { ResendSms } from "./ResendSms";
import { WidgetLayout } from "../../components/WidgetLayout";
import { SubmitBtn } from "../../components/SubmitBtn";
import { filterInput, numbersRegExp } from "../../utils/validation";
import { ErrorAlert } from "../../modules/ui/components/StyledAlert";

type FormValues = {
  phoneNumber?: string;
  code?: string;
};

export const SigninModal = (props: {
  open?: boolean;
  handleClose?: () => void;
}) => {
  const { open = false, handleClose } = props;
  const [step, setStep] = useState<"phone" | "code">("phone");
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [smsResult, smsLoading, smsError, smsRequest] = useRequestCode();
  const [, loginLoading, loginError, loginRequest] = useLogin();

  const handleChangeStep = useCallback(() => {
    setStep("phone");
  }, []);

  useEffect(() => {
    if (smsResult) {
      setStep("code");
    }
  }, [smsResult]);

  const onSubmit = useCallback(
    (formData: FormValues) => {
      if (step === "phone") {
        if (formData.phoneNumber) {
          let phoneNumber = "+" + formData.phoneNumber.split("+").join("");
          smsRequest(phoneNumber);
          setPhoneNumber(phoneNumber);
        }
      } else if (step === "code") {
        if (phoneNumber && formData.code && smsResult?.salt) {
          loginRequest(phoneNumber, formData.code.trim(), smsResult.salt);
        }
      }
    },
    [smsResult, smsRequest, loginRequest, setPhoneNumber, phoneNumber, step]
  );

  const validate = useCallback(
    (formData: FormValues) => {
      const errors: ValidationErrors = {};

      if (step === "phone" && !formData.phoneNumber) {
        errors.phoneNumber = true;
      }

      if (step === "code" && !formData.code) {
        errors.code = true;
      }

      return errors;
    },
    [step]
  );

  const resendSms = useCallback(() => {
    if (phoneNumber) {
      return smsRequest(phoneNumber);
    }
  }, [smsRequest, phoneNumber]);

  const error = smsError || loginError;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // '& .MuiBackdrop-root': {
        //   background: 'none',
        // },
        // background: '#f0f0f0',
        // border: '22px solid #CF0000',
      }}
    >
      <Box
        sx={{
          position: "relative",
          background: "#f0f0f0",
          maxWidth: "600px",
          padding: 2,
          paddingTop: 4,
          paddingBottom: 4,
          borderRadius: "10px",
        }}
      >
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#A7B4BB",
          }}
        >
          <CloseIcon />
        </IconButton>
        <WidgetLayout
          autoHeight
          hideLogo
          title={
            step === "code" ? "Enter verification code" : "Enter phone number"
          }
          subtitle={
            step === "code"
              ? `Sent to ${phoneNumber}.`
              : "We may store and send a verification code to this number"
          }
          action={
            step === "code" && (
              <Button
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#2968EF",
                  textTransform: "capitalize",
                }}
                onClick={handleChangeStep}
              >
                Change
              </Button>
            )
          }
        >
          <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Stack
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Stack
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    width='100%'
                  >
                    {step === "phone" && (
                      <TextField
                        placeholder='+9998887766'
                        label='Phone Number'
                        name='phoneNumber'
                        required={true}
                        value={phoneNumber}
                        inputProps={{
                          onChange: (e) =>
                            filterInput(
                              (e.target as HTMLTextAreaElement).value,
                              numbersRegExp,
                              setPhoneNumber
                            ),
                        }}
                        type='tel'
                      />
                    )}
                    {step === "code" && (
                      <TextField
                        name='code'
                        required={true}
                        autoFocus
                        sx={{ width: "150px" }}
                        inputProps={{
                          style: {
                            paddingBottom: "14px",
                            textAlign: "center",
                            fontWeight: "800",
                            fontSize: "20px",
                            lineHeight: "28px",
                            letterSpacing: "0.5px",
                            color: "#1C1F21",
                          },
                        }}
                        variant='standard'
                        type='tel'
                      />
                    )}
                    <Box mt={4} />
                    {step === "code" && <ResendSms onResend={resendSms} />}
                  </Stack>
                  <SubmitBtn disabled={smsLoading || loginLoading}>
                    Continue
                  </SubmitBtn>
                  <Box mt={2} />
                  {error !== null && (
                    <ErrorAlert>{error.toString()}</ErrorAlert>
                  )}
                </Stack>
              </form>
            )}
          />
        </WidgetLayout>
      </Box>
    </Modal>
  );
};

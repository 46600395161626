import { Select, TextField } from "mui-rff";
import React, { FC } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { Stack } from "@mui/material";
import { filterInput, numbersRegExp } from "../../../../utils/validation";
import {
  CountriesPhoneNumbers,
  TCountryPhoneCode,
} from "../../../../models/CountryNumbers";

const CustomContainer = React.forwardRef((props, ref) => (
  <Stack width={360} direction='row' spacing={2}>
    {props.children}
  </Stack>
));

const CustomInput = React.forwardRef((props, ref) => (
  <Stack flexBasis={"75%"}>
    <TextField
      fullWidth
      placeholder='9998887766'
      label='Phone Number'
      name='phoneNumber'
      required={true}
      type='tel'
    />
  </Stack>
));

const CustomSelect = React.forwardRef(
  (props: { countryCode: string; setCountryCode: (e: any) => void }, ref) => {
    return (
      <Stack flexBasis={"25%"}>
        <Select
          label='Code'
          name='countryCode'
          value={props.countryCode}
          inputProps={{
            onChange: (e: any) => props.setCountryCode(e.target.value),
          }}
          //@ts-ignore
          renderValue={(value) => value.callingCode}
          required={true}
          data={CountriesPhoneNumbers}
        />
      </Stack>
    );
  }
);

interface IPhoneInputWithCountry {
  phoneNumber: string;
  setNumber: (e: any) => void;
  countryCode: TCountryPhoneCode;
  setCountryCode: (e: any) => void;
}

export const PhoneInputWithCountry: FC<IPhoneInputWithCountry> = ({
  phoneNumber,
  setNumber,
  countryCode,
  setCountryCode,
}) => {
  const onChangeNumber = (e: any) =>
    filterInput(
      (e.target as HTMLTextAreaElement).value,
      numbersRegExp,
      setNumber
    );

  return (
    <PhoneInputWithCountrySelect
      placeholder='Enter phone number'
      international
      defaultCountry='LU'
      value={phoneNumber}
      inputComponent={CustomInput}
      containerComponent={CustomContainer}
      countrySelectComponent={CustomSelect}
      countrySelectProps={{ countryCode, setCountryCode }}
      onChange={onChangeNumber}
    />
  );
};

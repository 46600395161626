import { FC } from "react";
import { Box, Container, Typography, Stack } from "@mui/material";
import { WidgetLogo } from "../../assets/icons";
import { WidgetStepper } from "../../modules/ui/components/Stepper";

export const WidgetLayout: FC<{
  children: React.ReactNode;
  title?: string | number;
  subtitle?: string | number;
  action?: React.ReactNode;
  // temp V
  autoHeight?: boolean;
  hideLogo?: boolean;
  currentStep?: number;
  type?: string;
  countOfSteps?: number;
  completed?: {
    [k: number]: boolean;
  };
}> = ({
  children,
  title,
  subtitle,
  action,
  autoHeight,
  hideLogo = false,
  currentStep,
  countOfSteps,
  completed,
  type,
}) => {
  return (
    <Container maxWidth='md'>
      <Stack
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
        // height='calc(100vh - 120px)'
        // marginTop='120px'
        minHeight={autoHeight ? "auto" : "calc(100vh - 80px)"}
        marginTop={autoHeight ? "0" : "80px"}
      >
        <Box
          maxWidth={currentStep === (countOfSteps ?? 0) - 1 ? "770px" : "432px"}
          // minHeight='550px'
          minHeight={autoHeight ? "auto" : "550px"}
          width='100%'
        >
          {currentStep !== undefined && type !== "sharing" ? (
            <WidgetStepper
              countOfSteps={countOfSteps}
              completed={completed}
              currentStep={currentStep}
            />
          ) : (
            <></>
          )}

          {(title || subtitle || action) && (
            <Stack
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              gap='12px'
              mb='32px'
            >
              {title && (
                <Typography
                  variant='h4'
                  sx={{
                    fontWeight: "800",
                    fontSize: "32px",
                    lineHeight: "40px",
                    textAlign: "center",
                    letterSpacing: "0.5px",
                    color: "#1C1F21",
                  }}
                >
                  {title}
                </Typography>
              )}
              {(subtitle || action) && (
                <Stack
                  flexDirection='row'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#1C1F21",
                    }}
                  >
                    {subtitle}
                  </Typography>
                  {action && action}
                </Stack>
              )}
            </Stack>
          )}
          {children}
        </Box>
        {!hideLogo && (
          <Box pb='40px' pt='20px'>
            <img src={WidgetLogo} alt='logo' />
          </Box>
        )}
      </Stack>
    </Container>
  );
};

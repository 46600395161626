import { Box, Stack } from "@mui/material";
import { SelectData, TextField } from "mui-rff";
import { useTranslation } from "react-i18next";
import countries from "../../../data/counties.json";
import { useForm } from "react-final-form";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { getMaxDate } from "../../../utils/getMaxDate";
import { TDocTypesComponents } from "../../../models";
import { SelectWithId } from "../../../../ui/components/SelectWIthId";

const nationalitySelectData: SelectData[] = countries.map((x) => ({
  label: x.name,
  value: x.code,
}));

export const UserInformation = (props: TDocTypesComponents) => {
  const { domId } = props;
  const [dateOfBirth, setDateOfBirth] = useState<Dayjs | null>(null);
  const form = useForm();
  const { t } = useTranslation("onBoarding", {
    keyPrefix: "UserInformation.fields",
  });

  const handleChangeDate = (value: any) => {
    setDateOfBirth(value);
    form.change("data.date_of_birth", value.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    if (form.getState().values?.data?.date_of_birth)
      setDateOfBirth(
        dayjs(form.getState().values?.data?.date_of_birth, "YYYY-MM-DD")
      );
  }, [form]);

  return (
    <Stack id={domId} spacing={2} width='100%'>
      <TextField name='data.first_name' label={t("first_name")} required />
      <TextField name='data.last_name' label={t("last_name")} required />
      <DatePicker
        maxDate={getMaxDate()}
        defaultValue={getMaxDate()}
        value={dateOfBirth}
        onChange={(value: any) => handleChangeDate(value)}
        label={t("date_of_birth")}
        format='DD-MM-YYYY'
        slotProps={{
          textField: {
            required: true,
          },
        }}
      />
      <SelectWithId
        MenuProps={{ sx: { height: window.innerWidth < 400 ? 600 : "auto" } }}
        name='data.nationality'
        label={t("nationality")}
        required
        data={nationalitySelectData}
      />
      <SelectWithId
        MenuProps={{ sx: { height: window.innerWidth < 400 ? 600 : "auto" } }}
        name='data.middle_name'
        label={t("middle_name")}
        data={[{ label: "None", value: undefined }, ...nationalitySelectData]}
      />
      <SelectWithId
        MenuProps={{ sx: { height: window.innerWidth < 400 ? 600 : "auto" } }}
        name='data.country_of_birth'
        label={t("country_of_birth")}
        required
        data={nationalitySelectData}
      />
      <Box sx={{ display: "none" }}>
        <TextField name='data.date_of_birth' />
      </Box>
    </Stack>
  );
};

import { SelectData } from "mui-rff";
import countries from "../../data/counties.json";
import { required } from "../../../../utils/validation";
import { FC } from "react";
import { TVerificationAppFieldConfigComponent } from "../../models";
import { SelectWithId } from "../../../ui/components/SelectWIthId";

const nationalitySelectData: SelectData[] = countries.map((x) => ({
  label: x.name,
  value: x.code,
}));

export const CountrySelect: FC<TVerificationAppFieldConfigComponent> = ({
  name,
  label,
  required: isRequired,
}) => {
  return (
    <SelectWithId
      MenuProps={{ sx: { height: window.innerWidth < 400 ? 600 : "auto" } }}
      name={`data.${name}`}
      label={label}
      required={isRequired}
      // fieldProps={{ validate: isRequired ? required : undefined }}
      data={nationalitySelectData}
    />
  );
};

import { useCallback, useMemo, useState } from "react";
import { Alert, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { WidgetLayout } from "../../../../components/WidgetLayout";
import { ReviewStepItem } from "../../components/ReviewStepItem";
import { useOnBoardingFormContext } from "../../hooks/useOnBoardingFormContext";
import { SigninModal } from "../../../../screens/SigninModal";
import { useVerificationRequestSubmitWithPhone } from "../../hooks/useVerificationRequestSubmitWithPhone";
import { API_BASE_URL, ENV_FROM_URL } from "../../../../constants";
import { AML_STORAGE } from "../../models";
import { useStepper } from "../../../ui/hooks/useStepper";
import useLocalStorage from "../../../ui/hooks/useLocalStorage";
import { ErrorAlert } from "../../../ui/components/StyledAlert";

export const OnBoardingFormReviewScreen = () => {
  const { t } = useTranslation("onBoarding");
  const { formData, formConfig, verificationRequestId, files } =
    useOnBoardingFormContext();
  const [result, loading, error, submit, signinOpen, handleSigninClose] =
    useVerificationRequestSubmitWithPhone();
  const [showIdentStep, setShowIdentStep] = useState(false);
  const [email] = useLocalStorage("email", null);
  const [completed] = useStepper(6, formData);

  const readyToSend = useMemo(() => {
    const isAmlVisited = JSON.parse(
      localStorage.getItem(AML_STORAGE) ?? "false"
    );
    let ready = true;
    formConfig?.elements?.forEach((element, index) => {
      const stepIndex = index.toString();
      const docIsLoaded =
        element.type === "AmlStep"
          ? !isAmlVisited
            ? (files.Passport?.declineReason === null ||
                files.Id?.declineReason === null) &&
              files.Selfie?.declineReason === null
            : true
          : !!formData[stepIndex]?.documentId;
      if (!docIsLoaded) {
        ready = false;
      }
    });

    return ready;
  }, [formData, formConfig, files]);

  const handleSubmit = useCallback(() => {
    if (
      readyToSend ||
      (API_BASE_URL !== ENV_FROM_URL.PROD_ENV && showIdentStep)
    ) {
      submit(verificationRequestId);
    } else {
      setShowIdentStep(true);
    }
  }, [verificationRequestId, submit, readyToSend, showIdentStep]);

  if (Object.keys(formData).length === 0) {
    // If Empty go to first step
    return <Navigate to='../0' />;
  }

  if (result) {
    return <Navigate to='../success' />;
  }

  return (
    <WidgetLayout
      currentStep={formConfig?.elements?.length}
      title={t("Review.title")}
      subtitle={t("Review.subtitle")}
      completed={completed}
      countOfSteps={formConfig?.elements?.length}
    >
      <Stack flexDirection='column' spacing={2}>
        {formConfig?.elements?.map((element, index) => {
          const stepIndex = index.toString();
          return (
            <ReviewStepItem
              email={email}
              files={files}
              key={element.type}
              stepConfig={element}
              stepIndex={stepIndex}
              filledDocType={formData[stepIndex]?.documentType}
              formData={formData}
              // uploadState={loadingState[stepIndex]}
            />
          );
        })}
      </Stack>
      <Stack alignItems='center' width='100%' mt={4} spacing={2}>
        <Button
          variant='contained'
          onClick={handleSubmit}
          disabled={loading}
          sx={{ width: "160px", height: "44px" }}
        >
          Send
        </Button>
        {}
        {!loading && error && (
          <ErrorAlert>{error.message || error.toString()}</ErrorAlert>
        )}
        {showIdentStep && (
          <Alert severity='warning'>
            {"Identification step has not been completed"}
          </Alert>
        )}
      </Stack>
      <SigninModal open={signinOpen} handleClose={handleSigninClose} />
    </WidgetLayout>
  );
};

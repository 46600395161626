export const getSortDescending = (array: any[], field?: string) => {
  if (Array.isArray(array)) {
    return array.sort((a, b) => {
      if (field) {
        if (b[field] > a[field]) {
          return 1;
        }
        if (b[field] < a[field]) {
          return -1;
        }
        return 0;
      } else {
        if (b > a) {
          return 1;
        }
        if (b < a) {
          return -1;
        }
        return 0;
      }
    });
  } else {
    return [];
  }
};
export const getSortAscending = (array: any[], field?: string) => {
  if (Array.isArray(array)) {
    return array.sort((a, b) => {
      if (field) {
        if (b[field] < a[field]) {
          return 1;
        }
        if (b[field] > a[field]) {
          return -1;
        }
        return 0;
      } else {
        if (b < a) {
          return 1;
        }
        if (b > a) {
          return -1;
        }
        return 0;
      }
    });
  } else {
    return [];
  }
};

import { useCallback, useContext, useState } from 'react';
import { AuthContext, AuthContextValue } from './AuthProvider';

export const useLogin = (type: 'e-mail' | 'phone' = 'phone'): [
  boolean | null,
  boolean,
  Error | null,
  (loginData: string, code: string, salt: string) => void
] => {
  const { authWithCode, authWithEmailCode } = useContext<AuthContextValue>(AuthContext);
  const [result, setResult] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async (loginData: string, code: string, salt: string) => {
    setIsLoading(true);
    setError(null);

    try {
      await (type === 'phone' ? authWithCode(loginData, code, salt) : authWithEmailCode(loginData, code, salt));
      setResult(true);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('Unknown error'));
      }
    }
    setIsLoading(false);
  }, [authWithCode, authWithEmailCode, type]);

  return [result, isLoading, error, request];
};

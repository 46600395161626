import { Button, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { TextButton } from "../../modules/ui/components/TextButton";

type Props = {
  onResend: () => void;
  type?: "phone" | "e-mail";
  handleSwitchToEmail?: () => void;
};

const RESEND_DELAY = 60; // seconds

export const ResendSms = (props: Props) => {
  const { onResend, type, handleSwitchToEmail } = props;
  const [timeLeft, setTimeLeft] = useState(RESEND_DELAY);
  const [timerIsActive, setTimerIsActive] = useState(true);
  const [isResended, setIsResended] = useState(false);

  useEffect(() => {
    if (!timerIsActive) {
      return;
    }
    const timeout = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
      if (timeLeft <= 1) {
        setTimerIsActive(false);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [timeLeft, setTimeLeft, timerIsActive, setTimerIsActive]);

  const resend = useCallback(async () => {
    await onResend();
    setIsResended(true);
    setTimeLeft(RESEND_DELAY);
    setTimerIsActive(true);
  }, [onResend, setTimeLeft, setTimerIsActive]);

  return (
    <Stack sx={{ marginBottom: type === "phone" ? "32px" : "0" }}>
      <Button
        type='button'
        disabled={timeLeft > 0}
        onClick={resend}
        fullWidth
        sx={{
          marginBottom: type === "phone" ? "0" : "32px",
          fontWeight: "700",
          fontSize: "14px",
          lineHeight: "22px",
          textAlign: "center",
          letterSpacing: "0.5px",
          color: "#8D959F",
          textTransform: "capitalize",
        }}
      >
        {timeLeft > 0 ? `Resend code in ${timeLeft} sec` : "Resend code"}
      </Button>
      {/* {type === "phone" && handleSwitchToEmail && isResended ? (
        <Typography variant='body2'>
          Didn't receive the SMS? Proceed with{" "}
          <TextButton onClick={handleSwitchToEmail}>e-mail</TextButton>{" "}
          verification
        </Typography>
      ) : null} */}
    </Stack>
  );
};

import { Stack } from "@mui/material";
import { TextField } from "mui-rff";
import { useTranslation } from "react-i18next";
import { required } from "../../../../../utils/validation";
import { ChangeEvent } from "react";
import { useForm } from "react-final-form";
import { FileInput } from "../../../../ui/components/FileInput";
import { useOnBoardingFormContext } from "../../../hooks/useOnBoardingFormContext";
import { CardId } from "../../../../../assets/icons";
import { TDocTypesComponents } from "../../../models";
import { SelectWithId } from "../../../../ui/components/SelectWIthId";

const professional_status = [
  {
    value: "Employed",
    label: "Employed",
  },
  {
    value: "Unemployed",
    label: "Unemployed",
  },
  {
    value: "Retired",
    label: "Retired",
  },
  {
    value: "Self-employed",
    label: "Self-employed",
  },
  {
    value: "Student",
    label: "Student",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const Occupation = (props: TDocTypesComponents) => {
  const { domId } = props;
  const form = useForm();

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLButtonElement;
    if (!["Other", "Self-employed"].includes(target?.value)) {
      form.change("data.custom_message", "");
    }
  };
  const { t } = useTranslation("onBoarding", {
    keyPrefix: "Occupation.fields",
  });

  const {
    files: { Occupation },
    uploadedFiles,
  } = useOnBoardingFormContext();
  const OccupationFile__FirstPage = Occupation?.OccupationFile__FirstPage || {};

  return (
    <Stack id={domId} spacing={2} width='100%'>
      <TextField name='data.occupation' label={t("occupation")} />
      <SelectWithId
        name='data.professional_status'
        label={t("professional_status")}
        data={professional_status}
        required
        // fieldProps={{ validate: required }}
        inputProps={{ onChange: handleStatusChange }}
      />
      {["Other", "Self-employed"].includes(
        form.getState().values.data?.professional_status
      ) && (
        <TextField
          name='data.custom_message'
          label={t("custom_message")}
          required
          // fieldProps={{ validate: required }}
        />
      )}
      <TextField name='data.company_name' label={t("company_name")} />
      <TextField name='data.industry' label={t("industry")} />
      <FileInput
        isDeleteBan={uploadedFiles["OCCUPATION_FIRST_PAGE"]}
        document={OccupationFile__FirstPage}
        namePrefix='files.OCCUPATION_FIRST_PAGE'
        label={t("cv_letter")}
        subtitle={t("cv_subtitle")}
        icon={CardId}
        height={121}
      />
    </Stack>
  );
};

import { FC } from "react";
import { TVerificationAppFieldConfigComponent } from "../../models";
import { SelectData } from "mui-rff";
import { required } from "../../../../utils/validation";
import { Box, Typography } from "@mui/material";
import { SelectWithId } from "../../../ui/components/SelectWIthId";

export const SelectWithData: FC<TVerificationAppFieldConfigComponent> = ({
  name,
  label,
  subtitle,
  data,
  multiply,
  required: isRequred,
}) => {
  const selectData: SelectData[] =
    data?.map((dataItem) => ({
      label: dataItem,
      value: dataItem,
    })) ?? [];

  return (
    <Box>
      <SelectWithId
        name={`data.${name}`}
        label={label}
        data={selectData}
        required={isRequred}
        // fieldProps={{ validate: isRequred ? required : undefined }}
      />
      <Typography variant='caption' paddingLeft='12px'>
        {subtitle}
      </Typography>
    </Box>
  );
};

import { useCallback, useContext, useState } from 'react';
import { AuthContext, AuthContextValue } from './AuthProvider';

type Result = { salt: string };

export const useRequestCode = (type: 'phone' | 'e-mail' = 'phone'): [
  Result | null,
  boolean,
  Error | null,
  (loginData: string) => void
] => {
  const { requestSms, requestEmailCode } = useContext<AuthContextValue>(AuthContext);
  const [result, setResult] = useState<Result | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async (loginData: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await (type === 'phone' ? requestSms(loginData) : requestEmailCode(loginData));
      setResult(response);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('Unknown error'));
      }
    }
    setIsLoading(false);
  }, [requestSms, requestEmailCode, type]);

  return [result, isLoading, error, request];
};
